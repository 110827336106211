import { Component, Host, h, Element, Prop, State, Watch } from '@stencil/core';
import clsx from 'clsx';
import pdfjsLib from 'pdfjs-dist/legacy/build/pdf.js';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const zoomValues = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5];

@Component({
  tag: 'x-file-viewer',
  styleUrl: 'x-file-viewer.sass',
})
export class XFileViewer {
  @Element() el: HTMLElement;

  @Prop() src: string = '';

  @State() isPDF: boolean;
  @State() isLoading: boolean = true;
  @State() fit: 'width' | 'height';
  @State() flipX: boolean = false;
  @State() flipY: boolean = false;
  @State() rotate: number = 0;
  @State() zoom: number = 1;
  @State() numPages: number;
  @State() activePage: number = 1;
  @State() pixelRatio: number = 1;
  @State() showTooltip: boolean = false;

  tooltipTimeout;

  private viewportRef?: HTMLDivElement;

  private renderPDF = () => {
    const loadingTask = pdfjsLib.getDocument(this.src);

    let pdf;
    let currentPage = 1;

    const handlePages = page => {
      // We'll create a canvas for each page to draw it on
      const container = document.createElement('div');
      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d');

      // This gives us the page's dimensions at full scale
      const viewport = page.getViewport({
        scale: this.pixelRatio * pdfjsLib.PixelsPerInch.PDF_TO_CSS_UNITS,
      });

      canvas.width = viewport.width;
      canvas.height = viewport.height;
      canvas.style.width = `calc(${viewport.width}px * var(--x-file-viewer-zoom))`;
      canvas.style.height = `calc(${viewport.height}px * var(--x-file-viewer-zoom))`;

      container.classList.add('x-file-viewer__viewport-page');
      container.style.setProperty('--x-file-viewer-page-width', `${viewport.width}px`);
      container.style.setProperty('--x-file-viewer-page-height', `${viewport.height}px`);
      container.appendChild(canvas);

      // Draw it on the canvas
      page.render({ canvasContext, viewport });

      // Add it to the web page
      this.viewportRef.appendChild(container);

      // Move to next page
      currentPage++;
      if (pdf !== null && currentPage <= pdf.numPages) {
        pdf.getPage(currentPage).then(handlePages);
      }
    };

    loadingTask.promise.then(file => {
      pdf = file;
      this.numPages = pdf.numPages;
      this.isLoading = false;
      pdf.getPage(1).then(handlePages);
    });
  };

  private renderIMG = async () => {
    const container = document.createElement('div');
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');

    const img = (await new Promise(resolve => {
      const proxyImg = new Image();

      proxyImg.onload = function () {
        resolve(this as HTMLImageElement);
      };

      proxyImg.src = this.src;
    })) as HTMLImageElement;

    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    canvas.style.width = `calc(${img.naturalWidth}px * var(--x-file-viewer-zoom))`;
    canvas.style.height = `calc(${img.naturalHeight}px * var(--x-file-viewer-zoom))`;
    canvasContext.drawImage(img, 0, 0);

    container.classList.add('x-file-viewer__viewport-page');
    container.style.setProperty('--x-file-viewer-page-width', `${img.naturalWidth}px`);
    container.style.setProperty('--x-file-viewer-page-height', `${img.naturalHeight}px`);
    container.appendChild(canvas);

    this.viewportRef.appendChild(container);
    this.isLoading = false;
  };

  private showZoomTooltip = () => {
    clearTimeout(this.tooltipTimeout);
    this.showTooltip = true;

    this.tooltipTimeout = setTimeout(() => {
      this.showTooltip = false;
    }, 1200);
  };

  private actionZoomIn = () => {
    this.showZoomTooltip();

    for (let scale of zoomValues) {
      if (scale > this.zoom) {
        this.zoom = scale;
        this.fit = undefined;
        break;
      }
    }
  };

  private actionZoomOut = () => {
    this.showZoomTooltip();

    for (let i = zoomValues.length - 1; i > -1; i--) {
      const scale = zoomValues[i];

      if (scale < this.zoom) {
        this.zoom = scale;
        this.fit = undefined;
        break;
      }
    }
  };

  private actionFlipX = () => {
    this.flipX = !this.flipX;
  };

  private actionFlipY = () => {
    this.flipY = !this.flipY;
  };

  private actionRotate = () => {
    this.rotate = (this.rotate + 90) % 360;

    if (this.fit) {
      this.actionFit();
    }
  };

  private actionReset = () => {
    this.flipX = false;
    this.flipY = false;
    this.rotate = 0;
    this.zoom = 1;
    this.fit = undefined;
  };

  private actionFit = (e = null) => {
    const viewportComputedStyle = getComputedStyle(this.viewportRef);
    const viewportWidth = this.viewportRef.clientWidth - parseInt(viewportComputedStyle.paddingLeft, 10) - parseInt(viewportComputedStyle.paddingRight, 10);
    const viewportHeight = this.viewportRef.clientHeight;
    const currentPage = this.viewportRef.children[this.activePage - 1];
    const media = currentPage.firstElementChild as any;
    const isRotated = this.rotate === 90 || this.rotate === 270;

    // Switch value only on button click
    if (e) {
      this.fit = this.fit === 'width' ? 'height' : 'width';
    }

    if (this.fit === 'width') {
      this.zoom = (isRotated ? viewportWidth / media.height : viewportWidth / media.width) * this.pixelRatio;
    }
    if (this.fit === 'height') {
      this.zoom = (isRotated ? viewportHeight / media.width : viewportHeight / media.height) * this.pixelRatio;
      currentPage.scrollIntoView();
    }
  };

  private actionPrev = () => {
    const currentPage = this.viewportRef.children[this.activePage - 1];
    const nextPage = currentPage.previousElementSibling;

    if (nextPage !== null) {
      nextPage.scrollIntoView();
    }
  };

  private actionNext = () => {
    const currentPage = this.viewportRef.children[this.activePage - 1];
    const nextPage = currentPage.nextElementSibling;

    if (nextPage !== null) {
      nextPage.scrollIntoView();
    }
  };

  private handleScroll = e => {
    const pages = e.target.children;
    const hostRect = this.el.getBoundingClientRect();

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const pageRect = page.getBoundingClientRect();
      const pageOffset = pageRect.top - hostRect.top;
      const pageNumber = i + 1;
      const breakpoint = hostRect.height * 0.5;

      if (pageOffset >= 0) {
        this.activePage = pageOffset <= breakpoint ? pageNumber : pageNumber - 1;
        break;
      }
    }
  };

  private restart = () => {
    this.isLoading = true;
    this.numPages = undefined;
    this.activePage = 1;
    this.showTooltip = false;
    this.viewportRef.innerHTML = '';
    this.actionReset();
  };

  componentWillLoad() {
    this.isPDF = /\.pdf$/.test(this.src);
    this.pixelRatio = this.isPDF ? 2 : 1; // prerender pdf in bigger size for more readable zoom
  }

  componentDidLoad() {
    if (!this.src) {
      return;
    }

    if (this.isPDF) {
      this.renderPDF();
    } else {
      this.renderIMG();
    }
  }

  @Watch('src')
  watchSrc() {
    this.restart();
    this.componentWillLoad();
    this.componentDidLoad();
  }

  render() {
    return (
      <Host class="x-file-viewer">
        <div
          class={clsx('x-file-viewer__viewport', { '-flip-x': this.flipX }, { '-flip-y': this.flipY }, { '-orientation-landscape': this.rotate === 90 || this.rotate === 270 })}
          style={{ '--x-file-viewer-rotate': `${this.rotate}deg`, '--x-file-viewer-zoom': `${this.zoom / this.pixelRatio}` }}
          ref={el => (this.viewportRef = el as HTMLDivElement)}
          onScroll={this.handleScroll}
        >
          {this.isLoading && <div class="x-file-viewer__loading">{!this.src ? 'Error: no file specified' : 'Loading...'}</div>}
        </div>
        <div class="x-file-viewer__toolbar -direction-vertical" hidden={this.isLoading}>
          <div class="x-file-viewer__toolbar-isle">
            <div class="x-file-viewer__toolbar-group">
              <div class="x-file-viewer__toolbar-tooltip" hidden={!this.showTooltip}>
                {Math.round(this.zoom * 100)}%
              </div>
              <button type="button" class="x-file-viewer__control" onClick={this.actionFit}>
                <x-icon glyph={`viewer-fit-${this.fit === 'width' ? 'height' : 'width'}`} size="24"></x-icon>
              </button>
              <button type="button" class="x-file-viewer__control" onClick={this.actionZoomIn}>
                <x-icon glyph="viewer-zoom-in" size="24"></x-icon>
              </button>
              <button type="button" class="x-file-viewer__control" onClick={this.actionZoomOut}>
                <x-icon glyph="viewer-zoom-out" size="24"></x-icon>
              </button>
            </div>
          </div>
          <div class="x-file-viewer__toolbar-isle">
            <div class="x-file-viewer__toolbar-group">
              <button type="button" class="x-file-viewer__control" onClick={this.actionFlipX}>
                <x-icon glyph="viewer-flip-x" size="24"></x-icon>
              </button>
              <button type="button" class="x-file-viewer__control" onClick={this.actionFlipY}>
                <x-icon glyph="viewer-flip-y" size="24"></x-icon>
              </button>
              <button type="button" class="x-file-viewer__control" onClick={this.actionRotate}>
                <x-icon glyph="viewer-rotate" size="24"></x-icon>
              </button>
            </div>
          </div>
          <div class="x-file-viewer__toolbar-isle">
            <div class="x-file-viewer__toolbar-group">
              <button type="button" class="x-file-viewer__control" onClick={this.actionReset}>
                <x-icon glyph="viewer-reset" size="24"></x-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="x-file-viewer__toolbar -direction-horizontal" hidden={!this.isPDF || this.isLoading || this.numPages === 1}>
          <div class="x-file-viewer__toolbar-isle">
            <div class="x-file-viewer__toolbar-group">
              <input type="text" class="x-file-viewer__input" value={this.activePage} />
              <div class="x-file-viewer__text">of {this.numPages}</div>
            </div>
            <div class="x-file-viewer__toolbar-group">
              <button type="button" class="x-file-viewer__control" onClick={this.actionPrev}>
                <x-icon glyph="viewer-page-prev" size="24"></x-icon>
              </button>
              <button type="button" class="x-file-viewer__control" onClick={this.actionNext}>
                <x-icon glyph="viewer-page-next" size="24"></x-icon>
              </button>
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
