.x-file-viewer
  display: block
  position: relative
  background-color: var(--on-surface-dark)
  &__loading
    color: var(--on-surface-white)
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
  &__viewport
    height: 560px
    overflow: auto
    padding: 0 22px
    position: relative
    &-page
      width: calc(var(--x-file-viewer-page-width) * var(--x-file-viewer-zoom))
      height: calc(var(--x-file-viewer-page-height) * var(--x-file-viewer-zoom))
      position: relative
      overflow: hidden
      margin-left: auto
      margin-right: auto
      canvas
        display: block
        position: absolute
        top: 50%
        left: 50%
        transform: rotate(var(--x-file-viewer-rotate)) translate(-50%, -50%)
        transform-origin: top left
      &:not(:only-child)
        margin-top: 22px
        margin-bottom: 22px
      &:only-child
        min-height: 100%
    &.-flip-x &-page
      transform: rotateY(180deg)
    &.-flip-y &-page
      transform: rotateX(180deg)
    &.-flip-x.-flip-y &-page
      transform: rotateX(180deg) rotateY(180deg)
    &.-orientation-landscape &-page
      width: calc(var(--x-file-viewer-page-height) * var(--x-file-viewer-zoom))
      height: calc(var(--x-file-viewer-page-width) * var(--x-file-viewer-zoom))
  &__toolbar
    display: grid
    position: absolute
    right: 16px
    grid-gap: 4px
    &-isle
      display: grid
      background-color: var(--on-surface-white)
      border-radius: 6px
      padding: 4px
      grid-gap: 16px
      grid-auto-flow: inherit
    &-group
      display: grid
      grid-gap: 2px
      grid-auto-flow: inherit
    &-tooltip
      +preset(3)
      display: block
      position: absolute
      top: 0
      right: 100%
      background-color: var(--surface-white)
      border-radius: 4px
      padding: 8px
      margin: 54px 10px 0 0
      opacity: 1
      visibility: visible
      transition-property: opacity, visibility
      transition-duration: 200ms
      &:after
        content: ""
        display: block
        position: absolute
        top: 50%
        margin-top: -4px
        left: 100%
        width: 0
        height: 0
        border-style: solid
        border-width: 4px 0 4px 5px
        border-color: transparent transparent transparent var(--surface-white)
      &[hidden]
        opacity: 0
        visibility: hidden
    &.-direction-vertical
      top: 16px
      grid-auto-flow: row
    &.-direction-horizontal
      bottom: 16px
      grid-auto-flow: column
    &[hidden]
      visibility: hidden
  &__control
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    box-sizing: border-box
    background: none
    border: 1px solid var(--button-static-dark-grey)
    color: var(--icon-dark-50)
    border-radius: 4px
    &:hover
      border-color: var(--button-hover-green)
      color: var(--button-hover-green)
  &__input
    +preset(3)
    text-align: center
    width: 32px
    height: 32px
    box-sizing: border-box
    background: none
    border: 1px solid var(--button-static-dark-grey)
    color: var(--on-input-white-20)
    border-radius: 4px
    &:focus
      border-color: var(--button-hover-green)
  &__text
    +preset(3)
    color: var(--on-surface-dark-50)
    padding: 8px 0 0 5px
